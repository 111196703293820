// see node_modules/choices.js/assets/styles/scss/choices.scss
// for variables you can override
$choices-keyline-color: $dark-gray;
$choices-bg-color: $white;
$choices-guttering: 0;
$choices-button-icon-path: '../icons';
$choices-font-size-lg: 14px;
$choices-border-radius: 1px;
$choices-border-radius-item: 1px;
$choices-primary-color: #dddddd;
@use "bh-choices";
@forward "../bhv2_bootstrap";
@use "bh-selectize";
@use "bh-rc-time-picker";
@use "../settings/all" as *;
@use "../mixins/all" as *;


// overrides
.choices {
  &__input {
    background-color: $white;
  }

  &__item {
    color: $text;
    border-color: $green;
  }

  & + label {
    @include float-label-styles;
    position: absolute;
    margin-top: calc(-3em - 12px) !important;

    &[placeholder]::before {
      top: 0;
    }
  }
}
