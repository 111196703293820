@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.quotation, .b2bquotation {
  .language-picker {
    display: flex;
    flex-direction: column;
  }

  .logo {
    align-items: center;
    justify-content: space-between;


    h1 {
      margin: auto;
    }

    &--right {
      display: flex;
      flex-direction: column;
    }

    @include xxs-screen {
      flex-wrap: wrap-reverse;
      justify-content: center;

      &--right {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 15px;
        margin-bottom: 24px;

        ul {
          list-style: none;
          padding: 5px;

          li {
            margin-left: 0;
          }
        }

        img {
          margin: 0 auto;
        }
      }
      h1 {
        font-size: 24px;
      }
    }
  }

  &__text {
    padding: 0 10px;
  }

  table {
    page-break-inside: avoid;
  }

  &__table {
    text-align: center;
    width: 100%;

    tr:first-child {
      border-bottom: 1px solid $portal-grey;
    }

    td:first-child {
      text-align: left;
      width: 25%;
      padding-left: 20px;

      @media screen and (max-width: $xs-screen) {
        padding-left: 0;
      }
    }
  }

  .teacher-profile {
    min-height: initial;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__avatar {
      border-radius: 50%;
      object-fit: cover;
      width: 15em;
      height: 15em;
      padding: 1em;
    }

    &__shortinfo__stats {
      display: flex;
      justify-content: space-around;
      padding-top: 1em;

      @include xxs-screen {
        flex-wrap: wrap;
        justify-content: flex-start;

        .teacher-profile__info {
          padding: 8px 4px;
          margin-right: 8px;
        }
      }
    }

    &__longinfo {
      width: 65%;
    }
  }

  &__identity-string-bottom {
    bottom: -50px;
  }

  @media print {
    #download, .language-picker {
      display: none;
    }

    .teacher-profile {
      &__avatar {
        width: 175px;
        height: 175px;
      }

      &__info {
        max-width: 33%;
      }

      &__shortinfo__stats {
        justify-content: normal;
      }
    }
  }

  @include xxs-screen {
    .teacher-profile {
      flex-direction: column;

      &__longinfo {
        width: 100%;
      }

      &__title-row {
        justify-content: center;
      }

      &__full-name {
        font-size: 24px;
      }

      &__avatar {
        width: 10em;
        height: 10em;
      }
    }
  }
}

.sign-modal {
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: var(--s1);
    margin-top: 12px;
  }
}
