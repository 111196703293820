@use "../../settings/all" as *;
@use "../../mixins/all" as *;

/*Reset bootstrap styles*/
.navbar-nav > li > a {
  display: unset;
  padding: 6px 0;

  @include underline();
}

.open > .dropdown-menu {
  display: flex;
}

.navbar-default {
  border-color: transparent;
}

.navigation {
  transition: all $normal-duration ease 0s;
  background: transparent;
  min-width: 320px;
  //padding: 30px $sm-margin;
  margin: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  &.scrolled {
    background: $white;
  }

  i.fa.fa-bars {
    color: $dark-blue;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__right {
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 16px;
  }

  .navbar-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &.navbar-fixed-top {
    position: sticky;

    &--fixed {
      position: fixed;
    }
  }

  & + header {
    margin-top: 80px;
  }

  &__logo, &__logo img {
    max-height: 50px;
    margin-top: 2px;
  }

  &--fill {
    background: $white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    margin-top: 0 !important;
    z-index: 2000;
  }

  &.fixed {
    top: 0 !important;
    z-index: 1 !important;
  }

  #navbar-collapse {
    float: right;
  }

  &__link {
    .dropdown-toggle {
      background-color: transparent !important;
      border: none !important;
      font-weight: 700;
      font-size: var(--s0);
      color: $dark-blue;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .nav-dropdown__text {
        @include underline();
      }

      .toggle-icon {
        font-size: 24px;
      }
    }

    &--mobile-flexbox {
      padding: 0;
    }

    &--mobile-flex {
      justify-content: space-between;
      padding: 0;
      display: flex;
      text-align: center;
      list-style: none;
    }

    &--mobile-icon {
      padding-right: 6px;
      color: $portal-blue;
    }

    &--mobile {
      border: 1px solid #ccc;
      width: 50%;
      padding: 10px 6px;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .dropdown {

    .btn-default {
      color: $primary-blue;
      font-size: 22px;
      background-color: transparent;
      border: none;
    }

    .dropdown-menu {
      flex-direction: column;
      gap: 10px;
      align-items: center;
      top: 30px;
      min-width: 0;
      background: white;
      border-radius: 12px;
      padding: 10px;
      box-shadow: 0px 0px 30px 0px #0000001A;
      border: none;

      @include mobile-screen {
        position: absolute;
      }

      li {
        a {
          padding: 6px;
          color: $dark-blue;
          font-weight: 700;

          &:hover, :focus {
            color: $dark-blue;
            background-color: transparent;
          }

          @include underline();
        }
      }
    }
  }

  .navbar-toggle {
    border: none;
    font-weight: 600;
    border-radius: 2px;
    font-size: 1.2em;

    svg {
      width: 1.2em;
      height: 1.2em;
    }

    margin: 0;
    color: $dark-blue;

    &:focus {
      background-color: transparent;
    }
  }

  &__transparent {
    position: relative;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: transparent;
    padding: 0 !important;

    .navigation__link {
      font-size: 22px;
    }

    .navigation__logo {
      margin: 0;
    }
  }
  .nav-dropdown__item {
    font-size: var(--s-1);
  }
}

@media only screen and (min-width: $md-screen) {
  .navbar-header {
    float: left;
  }

  .navigation__link--only-mobile {
    display: none !important;
  }
}


@include medium-screen {
  .mobile-buttons {
    display: none;
  }
}

@media only screen and (max-width: $md-screen) {
  .navbar-header {
    float: none;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    i.fa.fa-bars {
      color: white;
    }
  }

  div.nav-menu {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
    position: sticky;
  }

  .collapse.in {
    display: block !important;
  }

  .mobile-buttons {
    margin-top: 70px;

    &__container {
      text-align: center;
      padding: 10px;

      .btn {
        padding: 11px 15px !important;
      }

      .btn--line {
        background: rgba(255, 255, 255, 1);
      }
    }

    &__link {
      font-weight: 600;
      width: 220px;
      font-size: 1.1em;

      &--login {
        background: transparent !important;
        color: $portal-blue;
      }
    }

    &__icon {
      padding-left: 6px;
    }
  }

  .main-nav {
    float: left;
    margin: 0;

    & > li:not(.language-picker):not(.language-dropdown), & > .navigation__right {
      display: none;
    }

    .navbar-nav .open .dropdown-menu {
      float: none;
    }

    &__btn--login, .main-nav__btn--info {
      display: none !important;
    }
  }
  .navigation {
    i.fa.fa-bars {
      color: $dark-blue;
      font-size: 24px;
    }

    #navbar-collapse {
      //  position: absolute;
      //  top: $nav-height;
      //  left: 0;
      //  width: 100vw;
      //  max-height: 100vh;
      //  border: 1px solid #ccc;
      //
      //  padding: 0;
      //  text-align: center;
      //  background: rgba(255, 255, 255, 1);
      //  overflow-x: hidden !important;

      //.dropdown-menu {
      //  text-align: center;
      //}
      //
      //ul {
      //  width: 100%;
      //  overflow-y: auto;
      //}
    }

    .dropdown .btn-default {
      float: right;
    }

    .navbar-toggle {
      span, i {
        font-size: var(--s0);
        text-transform: uppercase;
      }
    }

    @include xxs-screen {
      .menu-title {
        display: none;
      }
    }
  }

  .dropdownMenu2 {
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .navigation {
    //#navbar-collapse {
    //  top: $nav-height-mobile;
    //}
  }
  .container-fluid > .navbar-header {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .header-text h2 {
    font-size: 2.2em !important;
  }

  .header-text h4 {
    font-size: 1em !important;
  }

  .mobile-buttons--login {
    text-align: center !important;
  }
}

@media (max-width: $xs-screen) {
  .navigation {

    & + header {
      margin-top: 16px;
    }

    i.fa.fa-bars {
      color: $dark-blue;
    }

    .navbar-header {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      .navbar-toggle {
        display: flex;
        align-items: center;
        border-color: transparent;
        background-color: transparent;
        color: $primary-blue;
        height: 100%;
        padding: 0;

        i {
          font-size: 28px;
        }
      }
    }

    .navbar-right {
      flex-direction: column;
    }
  }

  .navbar.navbar-fixed-top__mobile {
    left: $mobile-menu-width;
    transition: left $fast-duration ease-in-out;

    &--closed {
      height: auto !important;
      flex-flow: wrap;
      left: 0;
      transition: left $fast-duration ease-in-out;
    }
  }
}

@media (min-width: $xs-screen + 1px) and (max-width: $tablet-vertical) {
  .navbar.navbar-fixed-top__mobile {
    left: 85%;
    transition: left $fast-duration ease-in-out;

    &--closed {
      height: auto;
      flex-flow: wrap;
      left: 0;
      transition: left $fast-duration ease-in-out;
    }
  }
}
