@use "../settings/all" as *;
@use "../mixins/all" as *;

html {
  text-rendering: optimizeLegibility !important;
  scroll-behavior: smooth;
}

html, body {
  overflow-x: clip;

  @media screen and (max-width: $xs-screen) {
    -webkit-overflow-scrolling: touch;
  }
}

// Dismiss tooltips on iOS
@supports (-webkit-touch-callout: none) {
  body {
    cursor: pointer;
  }
}

body {
  font-family: $default-family;
  color: $color-text;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-width: 320px;

  @include medium-screen {
    &.no-overflow__desktop {
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }

  &.fixed {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
  }


  &.overlay {
    transition: background 0.5s ease-in-out;
    &::after {
      content: "";
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

p {
  font-size: var(--s0);
}

// this should actually be a proper class name (component approach)
h1 {
  font-size: 2.4em;
}
h1, h2, h3 {
  font-family: $header-font;
}

/* Remove arrows from selects and replace them with custom arrow for unified appearance */
.select-arrow {
  border-color: #505050 transparent transparent;
  border-style: solid;
  border-width: 6px 3px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: absolute;
  right: 6px;
  top: 20px;
  -webkit-appearance: none;

  & + select {
    -moz-appearance: none;
    -webkit-appearance: none;
    text-indent: 0.01px;
    padding-right: 16px;
    text-overflow: '';
  }
}

.form-group {
  position: relative;
}

ul {
  list-style: none;
  padding-left: 0;
}

.horizontal-checkbox-select {
  > ul {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;

    label {
      display: flex;
      align-items: center;

      input {
        margin-right: 5px;
      }
    }
  }
}

.range-input {
  display: flex;

  input[type="number"] {
    margin-left: 20px;
  }
}

.site-logo {
  max-height: 50px;
}

// Used for elements the page needs to scroll to
.anchor {
  scroll-margin-top: $nav-height;
}
