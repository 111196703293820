@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.b2bquotation {
  --font-size: var(--s0);
  color: $dark-blue;
  padding-top: 20px;
  font-family: Nunito;

  &__logo {
    img {
      height: 38px;
      margin-bottom: 16px;
    }
  }

  .fa-check-circle {
    color: $primary-green;
  }

  &__cover {
    position: relative;

    &::after {
      content: '';
      background: linear-gradient(71.68deg, rgba(194, 223, 243, 0) 69.55%, #5C92B9 100%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 30px;
    }

    img {
      width: 100%;
      border-radius: 30px;
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__section {
    width: min(100%, 956px);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    padding: 35px;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--blue {
      background-color: #C2DFF3;
    }

    p {
      font-size: var(--font-size);
      margin-bottom: 10px;
    }

    &--dark-blue {
      background-color: $dark-blue;

      * {
        color: white;
      }
    }
  }

  &__section-img {
    max-width: 956px;
    margin: 0 auto;
    position: relative;

    &::after {
      content: '';
      background: linear-gradient(95.98deg, #5C92B9 12.14%, rgba(194, 223, 243, 0) 56.13%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 20px;
    }

    img {
      border-radius: 20px;
      width: 100%;
    }
  }

  &__header {
    position: relative;
    top: -220px;
    z-index: 1;
    margin-bottom: -190px;

    &--success {
      top: -180px;
      padding-bottom: 55px;
      margin-bottom: 180px;
    }
  }

  &__title, &__subtitle {
    font-family: $header-font;
    font-weight: 500;
    margin: 0;
  }

  &__title {
    font-size: 40px;
    line-height: 58px;
    margin-top: 24px;
  }

  &__subtitle {
    font-size: 20px;
    line-height: 26px;
  }

  &__text {
    margin: 0;
    padding: 0;
    font-size: var(--font-size);
  }

  &__actions {
    display: flex;
    gap: 16px;
  }

  &__section-title {
    font-family: $header-font;
    font-size: 28px;
    line-height: 41px;
    font-weight: 500;
    border-bottom: 1px dotted rgba(127, 162, 213, 1);

    .fas {
      display: none;
    }
  }

  &__footer {
    display: flex;
    gap: 150px;

    p {
      font-size: var(--font-size);
    }
  }


  .print-footer {
    display: none;
  }

  table {
    thead {
      display: none;
    }

    .details {
      width: 100%;

      tr {
        vertical-align: baseline;

        td:first-child {
          padding-right: 2em;
          width: 40%;
          font-weight: 700;
        }
      }
    }

    td {
      vertical-align: top;
      padding: 0.5em;

      &, & > p {
        font-size: var(--font-size);

        a {
          color: $light-orange;
        }
      }
    }
  }

  .references {
    display: flex;
    gap: 20px;

    .reference {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: var(--font-size);
      flex: 1;

      &__title {
        margin-bottom: 20px;
      }

      &__review {
        margin: 1em 0;

        p {
          font-size: var(--font-size);
          margin-bottom: 0.5em;
        }
      }

      &__review-text {
        margin-top: 20px;
      }
    }

    &__separator .line {
      border-top: solid 2px $yellow;
      margin: auto;
      width: 60%;
      padding-bottom: 10px;
    }

    &__email {
      font-style: italic;
      text-align: right;
      width: 100%;
    }


    img {
      max-height: 60px;
      margin: auto;
    }
  }

  @include mobile-screen {
    &__cover {
      height: 540px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &__sections {
      padding: 0 15px;
    }

    &__section {
      padding: 15px;
      box-shadow: 0px 0px 6px 0px #00000033;

      & + *:not(.modal) {
        display: initial;

        &.hidden {
          display: none;
        }
      }
    }

    &__section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      font-size: 24px;
      border: none;
      margin: 8px 0;

      .fas {
        display: inline-block;
        font-size: 22px;
        color: $primary-green;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    &__section-img {
      img {
        height: 200px;
        object-fit: cover;
      }
    }

    &__title {
      font-size: 28px;
      line-height: 41px;
      margin: 0;
    }

    &__actions {
      flex-direction: column;
      align-items: self-start;
    }

    table.details {
      tr {
        display: flex;
        flex-direction: column;

        td:first-child {
          padding-right: 2em;
          width: 100%;
          font-weight: 700;
        }
      }
    }

    .references {
      flex-direction: column;
      gap: 20px;
    }

    &__footer {
      gap: 0;
      flex-direction: column;
    }
  }

  .value-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    font-size: var(--s0);
    font-weight: 700;
    color: $dark-blue;
    padding: 0 !important;
    font-family: Nunito;

    &__value {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__title {
      font-size: var(--s0);
    }

    @include mobile-screen {
      display: none;
    }
  }
}

@include mobile-screen {
  .page--student-quotation {
    .portal__box, .portal__container {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

@media print {
  .b2bquotation {
    font-size: 10pt;
    line-height: 1.3;
    color: #000;

    /* Reset all positioning to prevent overlaps */
    * {
      position: static !important;
      float: none !important;
      overflow: visible !important;
    }

    /* Ensure all elements are block-level for proper flow */
    & > * {
      display: block;
      width: 100% !important;
      padding: 2cm;
    }

    &__section {
      break-inside: avoid;
      border-radius: 8pt;
      padding: 12pt;
      margin-bottom: 16pt;
      background-color: #fff !important;
      color: #000 !important;
      box-shadow: none;
      display: block;
      width: 100% !important;
      page-break-after: auto;
    }

    &__title {
      font-size: 18pt;
      font-weight: bold;
      margin-bottom: 12pt;
      border-bottom: 1pt solid #000;
      padding-bottom: 4pt;
    }

    &__subtitle {
      font-size: 14pt;
      font-weight: bold;
      margin-bottom: 8pt;
    }

    table thead {
      display: block;
    }

    table.details {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 4pt;

      th {
        font-weight: 500;
      }

      tr {
        td {
          vertical-align: top;
          padding: 2pt 4pt;
          font-size: var(--font-size);

          &:first-child {
            font-weight: bold;
            width: 30%;
          }
        }
      }
    }

    .references {
      display: block;
      width: 100%;

      .reference {
        margin-bottom: 12pt;

        img {
          max-height: 30pt;
          margin-bottom: 8pt;
        }
      }
    }
  }

  .fas .fa-chevron-up {
    display: none;
  }

  .b2bquotation__section-title {
    border: none
  }

  /* Add page numbers */
  @page {
    size: A4;
    margin: 2cm;
    @bottom-center {
      content: "Page " counter(page) " of " counter(pages);
      font-size: 9pt;
    }
  }

  /* Clear floats and fix container heights */
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }

  .no-print {
    display: none !important;
  }
}
