@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.bill {
  display: flex;
  flex-direction: column;


  @media print {
    display: block;
    height: 100vh;
    margin-bottom: -22px;
  }

  font-size: var(--s0);

  @media screen and (max-width: $xs-screen) {
    &, p {
      font-size: var(--s-1);
    }
  }

  > h1 {
    text-align: center;
    margin-bottom: 20px;

    font-weight: normal;

    @media screen and (max-width: $xs-screen) {
      font-size: 24px;
    }
  }

  .separator {
    height: 1px;
    background-color: $portal-grey
  }

  > table {
    margin-top: 14px;
    empty-cells: show;

    tr.column2 {
      display: none;
    }

    @media (max-width: $xs-screen) {
      td.column2 {
        display: none;
      }

      tr.column2 {
        display: table-row;
      }
    }
  }

  table.people {
    width: 100%;
  }


  > p, ul, table, strong {

    @media screen and (max-width: $xs-screen) {
      margin: 5px;
    }
  }

  ul {
    list-style: circle;
    margin-left: 1em;

    li {
      margin-left: 1em;
    }
  }

  &__section {
    background: $portal-blue !important;
    color: $white !important;

    &:not(tr) {
      display: flex;
      justify-content: space-between;
    }

    * {
      color: $white !important;
    }

    font-size: var(--s-1);
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
    padding: 10px;
    margin: 20px 0;

    .invoice__date {
      margin-right: 10px;
    }

    @media screen and (max-width: $xs-screen) {
      font-size: 12px;
      padding: 5px !important;
      height: auto;
      line-height: 16px;
    }
  }

  &__client {
    margin-bottom: 1em;
    width: 100%;

    th, td {
      &:first-child {
        padding-left: 10px;
      }
    }
  }

  &__articles {
    margin: 0 !important;
    table-layout: auto;
    width: 100%;
  }

  &__article {
    width: 100%;

    th, td {
      padding: 10px;
      white-space: nowrap;

      @media screen and (max-width: $xs-screen) {
        padding: 5px;
      }

      &:first-child {
        width: 100%;
        white-space: normal;
      }

      &:nth-child(n+3) {
        text-align: right;
      }

    }

    &--data-row {
      height: 40px;

      &:not(:first-child) {
        border-top: 1px solid lightgray;
      }

    }

    &--middle-row {
      margin-top: 20px;
      height: 40px;
      border-top: 1px solid black;
    }

    &--total-row {
      height: 40px;
      border-top: 1px solid black;
      font-weight: bold;
    }

    &--active-row {
      border-bottom: 1px solid black;
    }
  }

  &__footer {
    margin-left: 100px;
    page-break-inside: avoid;
  }

  &__remarks {
    color: $light-text !important;
    margin: 20px auto;
  }

  &__underlined-text {
    border-bottom: 1px solid lightgrey;
    color: $color-text;
    line-height: 40px;

    &--upper {
      text-transform: uppercase;
      border-bottom: 1px solid $black;
    }
  }

  a {
    color: $dark-blue !important;
    text-decoration: underline;
  }

  &__payment-details {

    &__data {
      display: inline-block;
      font-weight: bold;
    }


    &__title {
      display: inline-block;
      width: 150px;
    }
  }

  &__help-text {
    color: $light-text !important;
  }

  &__identity-string {
    @media screen {
      display: None;
    }

    text-align: center;
    color: $light-text !important;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    width: 100%;

    &-bottom {
      position: fixed;
      bottom: -30px;
      text-align: center;
      width: 100%;
    }
  }

  &__amount {
    width: 86px;
  }

  .logo {
    display: flex;
    justify-content: flex-end;

    img {
      height: 50px;
      margin: 5px;
    }
  }
}
