@use "../../settings/all" as *;
@use "../../mixins/all" as *;
@page {
  size: a4 portrait;
  margin: 10mm;
}

@media print {
  a[href]:after {
    content: none !important;
  }
}

/* Common styles for all types of invoices */
.invoices {

  @media print {
    font-size: 12px;

    p, li, td {
      font-size: 12px;
    }
  }

}

.invoice {

  &__note {
    margin-top: 1em;

    &--centered {
      text-align: center;
    }

    &--light {
      color: $light-text !important;
    }
  }

  &__heading {
    text-transform: uppercase;
    color: $portal-blue !important;
  }

  &__number {
    margin-right: 14px;
  }

  &__section {

    @media print {
      * {
        color: $white !important;
        font-size: 14px !important;
      }
    }

    width: 100%;
    height: 40px;
    color: $white;
    font-size: var(--s0);
    font-weight: bold;
    text-transform: uppercase;
    line-height: 40px;
    padding: 0 5px !important;
    margin: 30px auto;

    &--invoice {
      background-color: $yellow !important;
    }

    &__heading {
      margin-right: 1em;
    }

    @media screen and (max-width: $xs-screen) {
      font-size: 12px;
      padding: 5px !important;
      height: auto;
      line-height: 16px;
    }
  }

  &__articles {
    width: 100%;
  }

  &__totals {
    margin-top: 3em;
  }

  &__row-title {
    color: $portal-blue !important;
    width: 300px;
    display: inline-block;
  }

  &__row-currency {
    display: inline-block;
  }

  &__address {
    position: fixed;
    text-align: center;
    width: 100%;
    bottom: 0;
  }
}

// Helper class
.no-padding {
  padding: 0 !important;
}
