@use "../settings/all" as *;

@mixin underline {
  &:hover {
    background-color: unset;
    text-decoration: underline;
    text-decoration-color: $primary-green;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
  }
}
