@use "../../settings/all" as *;
@use "../../mixins/all" as *;
.invoice-unclaimed-refund {
  &__article {

    &--header-row {
      height: 40px;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;

      th {
        font-weight: normal;
        min-width: 90px !important;
      }
    }

    &--data-row {
      height: 40px;

      td {
        min-width: 90px !important;
      }
    }
  }

  .row {
    margin: auto;
  }

  .row::before {
      display: none;
  }
  .row:after{
      display: none;
  }

  .logo {
    display: flex;
    justify-content: space-between;

    img {
      height: 50px;
    }
  }
}
