@use "../settings/all" as *;
@import "selectize";

.form-control.selectize-control {
  padding: 0;
  height: 46px;
  border: none;
  color: $text;

  .selectize-input {
    border-radius: 6px;
    height: 44px;
    padding: 12px;
    box-shadow: none;
    font-weight: 500;
    color: $text;

    &.input-active {
      border-color: $text;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      & ~ .selectize-dropdown > .selectize-dropdown-content {
        border-color: $text;
      }
    }

    input {
      color: $portal-grey;
      font-size: var(--s-1);
    }
  }
}

.selectize-dropdown {
  height: auto;
  padding: 0;
  border: none;
  border-bottom: 1px solid $text;
  color: $text;

  &.form-control-custom {
    height: unset;
  }
}

.selectize-dropdown-content {
  background: $white;
  border: 1px solid $border-color;
  border-bottom: none;
  max-height: 180px;
  overflow-y: auto;
  color: $text;
}

/* Style select arrow */
.selectize-control {
  &.single, &.multi, &.selectize-multiple {
    .selectize-input {
      padding-right: 40px !important;

      &:after {
        content: none;
      }

      &.dropdown-active {
        .arrow-box {
          top: calc(50% + 2px);
        }

        .arrow-box::after {
          transform: rotate(225deg);
        }
      }
    }

    .arrow-box {
      box-sizing: border-box;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 100px;
      color: $primary-green;
    }

    .arrow-box::after {
      content: "";
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 3px solid;
      border-right: 3px solid;
      transform: rotate(45deg);
      left: 4px;
      top: 2px
    }

    &.multi {
      .selectize-input.has-items {
        line-height: 2.2;
      }
    }
  }
}

/**
* Special styles for open assignments multi filter
 */
#id_multi_filter ~ .form-control.selectize-control {
  height: unset;

  .selectize-input {
    height: auto;
  }

  .arrow-box {
    display: none;
  }
}

