@use "../../settings/all" as *;
@use "../../mixins/all" as *;
/* Invoice type B */

.invoice-to-teacher {

  &__heading {
    text-transform: uppercase;
    color: $portal-blue !important;
  }

  &__section {
    background-color: $yellow !important;
  }

  &__article {

    &--header-row {
      height: 40px;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;

      th {
        font-weight: normal;
      }
    }

    &--data-row {
      height: 40px;
    }
  }

  &__identity-string {
    color: $light-text !important;
    margin: 3em 0 !important;
    font-size: 12px !important;
    text-align: center;
    line-height: 3em;
  }
}
